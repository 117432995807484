<template>
  <div class="card card-custom card-transparent">
    <div class="card-header">
      <div>
        <span
          ><strong>{{ selectedDevice.name }}</strong></span
        >
        <span v-if="selectedDevice.location">
          {{ selectedDevice.location.lessor.name }},</span
        >
        <span v-if="selectedDevice.location">
          {{ selectedDevice.location.title }},
        </span>
        <span
          v-for="tag in selectedDevice.tags"
          :key="tag.id"
          class="label label-lg label-light-primary label-inline mr-2"
          >{{ tag.name }}</span
        >
      </div>
    </div>
    <div class="card-body p-0">
      <!--begin: Wizard-->
      <div
        class="wizard wizard-4"
        id="kt_wizard_v4"
        data-wizard-state="step-first"
        data-wizard-clickable="true"
      >
        <!--begin: Wizard Nav-->
        <div class="wizard-nav">
          <div class="wizard-steps">
            <div
              class="wizard-step"
              data-wizard-type="step"
              data-wizard-state="current"
            >
              <div class="wizard-wrapper">
                <div class="wizard-number">1</div>
                <div class="wizard-label">
                  <div class="wizard-title">Prikupiti novac</div>
                  <div class="wizard-desc"></div>
                </div>
              </div>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-wrapper">
                <div class="wizard-number">2</div>
                <div class="wizard-label">
                  <div class="wizard-title">Testirati aparat</div>
                  <div class="wizard-desc"></div>
                </div>
              </div>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-wrapper">
                <div class="wizard-number">3</div>
                <div class="wizard-label">
                  <div class="wizard-title">Provjera naljepnica</div>
                  <div class="wizard-desc"></div>
                </div>
              </div>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-wrapper">
                <div class="wizard-number">4</div>
                <div class="wizard-label">
                  <div class="wizard-title">Očistiti žetonjeru</div>
                  <div class="wizard-desc"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end: Wizard Nav -->

        <!--begin: Wizard Body-->
        <div class="card card-custom card-shadowless rounded-top-0">
          <div class="card-body p-0">
            <div class="row justify-content-center py-8 px-8 py-lg-15 px-lg-10">
              <div class="col-xl-12 col-xxl-7">
                <!--begin: Wizard Form-->
                <form class="form mt-0 mt-lg-10" id="kt_form">
                  <!--begin: Wizard Step 1-->
                  <div
                    class="pb-5"
                    data-wizard-type="step-content"
                    data-wizard-state="current"
                  >
                    <div class="row">
                      <div class="form-group">
                        <label>Prikupljen novac?</label>
                        <VtSwitch
                          @checkedEvent="onMoneyRaisedChange"
                          :is-checked="false"
                          classes="switch-md switch-icon switch-outline switch-primary"
                          name="deviceServiceMode"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="form-group">
                        <label>Resetiran uredaj?</label>
                        <VtSwitch
                          @checkedEvent="onDeviceResetChange"
                          :is-checked="false"
                          classes="switch-md switch-icon switch-outline switch-primary"
                          name="deviceServiceMode"
                        />
                      </div>
                    </div>
                  </div>
                  <!--end: Wizard Step 1-->

                  <!--begin: Wizard Step 2-->
                  <div class="pb-5" data-wizard-type="step-content">
                    <div class="row">
                      <div class="form-group">
                        <label>Testiran aparat?</label>
                        <VtSwitch
                          @checkedEvent="onDeviceTestedChange"
                          :is-checked="false"
                          classes="switch-md switch-icon switch-outline switch-primary"
                          name="deviceServiceMode"
                        />
                      </div>
                    </div>
                  </div>
                  <!--end: Wizard Step 2-->

                  <!--begin: Wizard Step 3-->
                  <div class="pb-5" data-wizard-type="step-content">
                    <div class="row">
                      <div class="form-group">
                        <label>Provjerena naljepnica?</label>
                        <VtSwitch
                          @checkedEvent="onLabelCheckedChange"
                          :is-checked="false"
                          classes="switch-md switch-icon switch-outline switch-primary"
                          name="deviceServiceMode"
                        />
                      </div>
                    </div>
                  </div>
                  <!--end: Wizard Step 3-->

                  <!--begin: Wizard Step 4-->
                  <div class="pb-5" data-wizard-type="step-content">
                    <div class="row">
                      <div class="form-group">
                        <label>Ociscena zetonjara?</label>
                        <VtSwitch
                          @checkedEvent="onCoinAcceptorCleanedChange"
                          :is-checked="false"
                          classes="switch-md switch-icon switch-outline switch-primary"
                          name="deviceServiceMode"
                        />
                      </div>
                    </div>

                    <div class="row">
                      <div style="width: 100%" class="form-group">
                        <label>Komentar</label>
                        <textarea
                          v-model="comment"
                          class="form-control form-control-solid"
                          type="text"
                          rows="5"
                        >
                        </textarea>
                      </div>
                    </div>
                  </div>
                  <!--end: Wizard Step 4-->

                  <!--begin: Wizard Actions -->
                  <div class="d-flex justify-content-between border-top pt-10">
                    <div class="mr-2">
                      <button
                        class="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4"
                        data-wizard-type="action-prev"
                      >
                        Previous
                      </button>
                    </div>
                    <div>
                      <button
                        v-on:click="submit"
                        class="btn btn-success font-weight-bold text-uppercase px-9 py-4"
                        data-wizard-type="action-submit"
                      >
                        Submit
                      </button>
                      <button
                        class="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                        data-wizard-type="action-next"
                      >
                        Next Step
                      </button>
                    </div>
                  </div>
                  <!--end: Wizard Actions -->
                </form>
                <!--end: Wizard Form-->
              </div>
            </div>
          </div>
        </div>
        <!--end: Wizard Bpdy-->
      </div>
      <!--end: Wizard-->
    </div>
  </div>
</template>

<style lang="scss">
@import '@/assets/sass/pages/wizard/wizard-4.scss';
</style>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import KTUtil from '@/assets/js/components/util'
import KTWizard from '@/assets/js/components/wizard'
import VtSwitch from '@/view/content/components/switch/VtSwitch'
import ApiService from '@/core/services/api.service'
import { mapGetters } from 'vuex'

export default {
  name: 'MaintenanceWizard',
  components: {
    VtSwitch,
  },
  props: {
    selectedDeviceId: {
      type: String,
    },
    selectedDevice: {
      type: Object,
    },
    isSingle: {
      type: Boolean,
    },
  },
  watch: {
    selectedDeviceId: function () {},
  },
  computed: {
    ...mapGetters(['currentUserAccountInfo', 'currentUserPersonalInfo']),
  },
  data() {
    return {
      isMoneyRaised: false,
      isDeviceReset: false,
      isDeviceTested: false,
      isLabelChecked: false,
      isCoinAcceptorCleaned: false,
      comment: null,
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Devices', route: '/devices/list' },
      { title: 'Maintenance' },
    ])

    // Initialize form wizard
    const wizard = new KTWizard('kt_wizard_v4', {
      startStep: 1, // initial active step number
      clickableSteps: true, // allow step clicking
    })

    // Validation before going to next page
    wizard.on('beforeNext', function (/*wizardObj*/) {
      // validate the form and use below function to stop the wizard's step
      // wizardObj.stop();
    })

    // Change event
    wizard.on('change', function (/*wizardObj*/) {
      setTimeout(() => {
        KTUtil.scrollTop()
      }, 500)
    })
  },
  methods: {
    onMoneyRaisedChange() {
      this.isMoneyRaised = !this.isMoneyRaised
    },
    onDeviceResetChange() {
      this.isDeviceReset = !this.isDeviceReset
    },
    onDeviceTestedChange() {
      this.isDeviceTested = !this.isDeviceTested
    },
    onLabelCheckedChange() {
      this.isLabelChecked = !this.isLabelChecked
    },
    onCoinAcceptorCleanedChange() {
      this.isCoinAcceptorCleaned = !this.isCoinAcceptorCleaned
    },
    submit: function (e) {
      e.preventDefault()
      ApiService.post('device_maintenances', {
        author: this.currentUserPersonalInfo.email,
        data: {
          isMoneyRaised: this.isMoneyRaised,
          isDeviceReset: this.isDeviceReset,
          isDeviceTested: this.isDeviceTested,
          isLabelChecked: this.isLabelChecked,
          isCoinAcceptorCleaned: this.isCoinAcceptorCleaned,
          comment: this.comment,
        },
        device: this.selectedDeviceId,
      }).then(() => {
        this.$notify({
          group: 'notification',
          type: 'success',
          title: 'Thanks for caring for the device',
        })
        if (this.isSingle) {
          this.$router.push({ name: 'devices-list' })
        } else {
          setTimeout(() => {
            this.$root.$emit('bv::hide::modal', 'device_maintenance_modal')
          }, 200)
        }
      })
    },
  },
}
</script>
